import React, { useEffect, useState } from 'react'
import { Route } from 'react-router'
import axios from 'axios'
import io from 'socket.io-client'
import Homepage from 'pages/Homepage'
import FamilyTree from 'pages/FamilyTree'
import Profile from 'pages/Profile'
import KittyHats from 'pages/KittyHats'
import Report from 'pages/Report'
import Audit from 'pages/Audit'
import Total from 'components/Total'
import ScrollTop from 'components/ScrollTop'
import Chat from 'components/Chat'
import Confetti from 'components/Confetti'
import CryptoKittiesContract from 'contracts/cryptokitties'
import Contract from 'contracts/kitty-hats'
import Connect from 'components/Connect'
import Notifications from 'components/Notifications'
import Search from 'pages/Search'

const { REACT_APP_END_POINT } = process.env

const AppView = ({ loggedIn, handleSignIn, handleSignOut, web3, user, token, checkToken }) => {
	const [socket, setSocket] = useState(undefined)
	const [hats, setHats] = useState(undefined)
	const [allHatEvents, setAllHatEvents] = useState()
	const [total, setTotal] = useState(undefined)
	const [catsWithHats, setCatsWithHats] = useState(undefined)
	const [searchables, setSearchables] = useState([])
	const [showCelebration, setShowCelebration] = useState(false)
	const [newPurchaseKitty, setNewPurchaseKitty] = useState(undefined)
    const [notifications, setNotifications] = useState([])

	useEffect(() => {
		const getSearchables = async () => {
			const { data } = await axios(`${REACT_APP_END_POINT}/cryptokitties/cattributes`)
			setSearchables(data)
		}
		getSearchables()
	}, [])

	useEffect(() => {
        const getKittyHats = async () => {
            const { data } = await axios.get(`${REACT_APP_END_POINT}/kitty-hats/hats`)
            const _data = [...data.filter(({ available }) => available > 0), ...data.filter(({ available }) => available < 1)]
            setHats(_data)
        }
		getKittyHats()
	}, [])

	useEffect(() => {
		// Close the existing socket when the component unmounts or when the token changes
		const closeSocket = () => {
            if (socket) {
                socket.close()
                setSocket(null)
            }
		}
		const createSocket = () => {
            const _socket = io(REACT_APP_END_POINT + '/kittyfamily', { transports: ['websocket'], auth: { token }, })
            _socket.on('connect_error', (err) => console.log(`connect_error due to ${err}`))
            _socket.on('connect', () => setSocket(_socket))
            _socket.on('disconnect', () => setSocket(null))
            _socket.emit('ckReport')
		}
	  
		// Close the existing socket and create a new one when the component mounts or when the token changes
		closeSocket()
		createSocket()
		// Clean up when the component unmounts
		return () => {
		    closeSocket()
		}
    }, [token])

	const handlePurchase = async (tokenId, value) => {
		try {
			if (loggedIn) {
				const instance = new web3.eth.Contract(
					CryptoKittiesContract.Sale.abi,
					CryptoKittiesContract.Sale.addr
				)
                const gas = await instance.methods.bid(tokenId).estimateGas({ from: loggedIn, value })
				const sale = await instance.methods.bid(tokenId).send({ from: loggedIn, value, gas })
				return true
			} else {
				handleSignIn()
				return false
			}
		} catch (e) {
			console.log(e)
			return false
		}
	}

	const handleHatPurchase = async (item, value) => {
        const _item = item.replace('Item', '')
        try {
            if (loggedIn) {
                const instance = new web3.eth.Contract(Contract.Core.abi, Contract.Core.addr)
                const checkItem = await instance.methods.getItem(_item).call()
                const gas = await instance.methods.buyItem(_item, '1').estimateGas({ from: loggedIn, value })
                const sale = await instance.methods.buyItem(_item, '1').send({ from: loggedIn, value, gas })
                setShowCelebration(true)
				return true
            } else {
				handleSignIn()
				return false
			}
        } catch (e) {
            console.log(e)
			return false
        }
    }

    const handleHatPurchaseAndApply = async (tokenId, item, value) => {
        const _item = item.replace('Item', '')
        try {
            if (loggedIn) {
                const instance = new web3.eth.Contract(Contract.Core.abi, Contract.Core.addr)
                const checkItem = await instance.methods.getItem(_item).call()
				try {
                    const gas = await instance.methods.buyItemAndApply(_item, tokenId).estimateGas({ from: loggedIn, value: checkItem[1] })
					const sale = await instance.methods.buyItemAndApply(_item, tokenId).send({ from: loggedIn, value: checkItem[1], gas })
					setShowCelebration(true)
					return true
				} catch (e) {
					console.log(e)
				}
            } else {
				handleSignIn()
				return false
			}
        } catch (e) {
            console.log(e)
			return false
        }
    }

	useEffect(() => {
		let timeoutId
		if (showCelebration) {
			timeoutId = setTimeout(() => setShowCelebration(false), 2000)
		}
		return () => {
			clearTimeout(timeoutId)
		}
	}, [showCelebration])

    const addNotification = ({ account, tokenId, type }) => {
        setTotal(tokenId)
		setNotifications(prevNotifications => [...prevNotifications, { account, tokenId, type }])
		setTimeout(() => {
			setNotifications(prevNotifications => {
				const indexToRemove = prevNotifications.findIndex(notification => notification.tokenId === tokenId)
				return (indexToRemove !== -1)
                    ? [...prevNotifications.slice(0, indexToRemove), ...prevNotifications.slice(indexToRemove + 1)]
				    : prevNotifications
			})
		}, 10000)
	}

	return (
		<>
			{showCelebration && <Confetti usePortal />}
			<Total {...{ socket }} emit={addNotification} />
			<ScrollTop>
				<Route exact path="/" render={() => <Homepage {...{ total, hats, allHatEvents, loggedIn, web3, searchables, handleHatPurchase, handleHatPurchaseAndApply, handleSignIn }} />} />
				<Route path="/kitty/:id" render={props => <FamilyTree {...props} {...{ loggedIn, handlePurchase, searchables }} />} />
				<Route path="/profile/:profile" render={props => <Profile {...props} {...{ loggedIn, handlePurchase, searchables, token, handleSignIn, user, checkToken }} />} />
				<Route path="/kitty-hats" render={() => <KittyHats {...{ hats, allHatEvents, loggedIn, searchables, web3, handleHatPurchase, handleHatPurchaseAndApply, handleSignIn }} /> } />
				<Route path={'/search'} render={() => searchables && <Search {...{ searchables, handlePurchase }} account={{ wallet: loggedIn }} />} />
				<Route path={'/report'} render={() => <Report {...{ socket }} />} />
				{/* <Route path={'/audit'} render={() => <Audit {...{ socket }} />} /> */}
				<Chat  {...{ loggedIn, socket, user, catsWithHats, token }} />
				<Connect {...{ loggedIn, handleSignIn, handleSignOut }} />
                {/* <Notifications {...{ notifications }} /> */}
			</ScrollTop>
		</>
	)
}

export default AppView